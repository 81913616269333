import { 
    getFirestore,
    collection,
    doc,
    addDoc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    onSnapshot,
    serverTimestamp,
    getDoc,
    getDocs,
    setDoc,
    writeBatch,
    query,
    where,
    limit,
    startAfter,
    orderBy,
    FieldPath,
    documentId,
} from "firebase/firestore";
// import firebase from "firebase/compat/app";
import app from "./firebase_config";
import { cleanUpUndefined, generateId } from "../helper_functions/parsers";

const db = getFirestore(app);

const addToCollection = async(collectionName, data) => {
    return await addDoc(collection(db, collectionName), data);
};

const writeArrayToFirestore = async(bigArr, uid, newDatabaseName) => {
    if(bigArr.length === 0) return [];

    const results = [];
    const limitedArrays = breakArray(bigArr, 50);

    for(let i = 0; i < limitedArrays.length; i++){
        const arr = limitedArrays[i];
        const batch = writeBatch(db);
        for(let j = 0; j < arr.length; j++){
            const element = arr[j];
            if(j === 0){
                const dbArrayRef = doc(db, "db_list", uid);
                batch.update(dbArrayRef, { list: arrayUnion(newDatabaseName) });
            }
            const docId = element.id ? element.id : generateId();
            const docRef = doc(db, "doc_entries", docId);
            batch.set(docRef, { collectorId: uid, database: newDatabaseName, ...element });
        }
        results[i] = await batch.commit();
    }
    
    return results;
};

const breakArray = (arr, size) => {
    if(arr.length <= size) return [arr];

    const arrArr = [];
    for(let i = 0; i * size < arr.length; i++){
        arrArr.push(arr.slice(i * size, (i + 1) * size));
    }

    return arrArr;
};

const getAllEntries = async(uid, databaseName) => {

    let result;
    try{
        const collectionRef = collection(db, "doc_entries");
        const q = query(collectionRef, where("collectorId", "==", uid), where("database", "==", databaseName));
        result = await getDocs(q);
    }
    catch(error){
        console.log("error: ", error);
    }
    const newAllEntries = result.docs.map(r => ({id: r.id, ...r.data()}));
    return newAllEntries;
};

const getEntries = async(uid, databaseName, lastDoc, pageSize = 100) => {
    const collectionRef = collection(db, "doc_entries");
    let q;
    if(lastDoc){
        q = query(
            collectionRef,
            where("collectorId", "==", uid),
            where("database", "==", databaseName),
            orderBy(documentId()),
            startAfter(lastDoc.id),
            limit(pageSize));
    }
    else{
        q = query(
            collectionRef,
            where("collectorId", "==", uid),
            where("database", "==", databaseName),
            orderBy(documentId()),
            limit(pageSize));
    }
    
    const result = await getDocs(q);
    const tableObject = result.docs.map(r => ({id: r.id, ...r.data()}));
    const newLastDoc = result.docs[result.docs.length - 1];

    return { tableObject, newLastDoc, empty: result.empty };
};

const addField = async(fieldDatum) => {
    // get all the fields that exist
    const docRef = doc(db, "settings", "fieldData");
    const data = (await getDoc(docRef)).data();
    const fieldData = data.fieldNames;
    // check if the field you're adding already exists
    const field = fieldData.find(fd => fd.fieldName === fieldDatum.fieldName);
    // if field doesn't exist add it
    if(!field){
        return addFieldToFirestore(fieldDatum);
    }
    // if the field exists and is disabled, enable it
    // otherwise do nothing
    if(field.disabled){
        field.disabled = false;
        const updatedFieldData = fieldData.map(fd => fd.fieldName === field.fieldName ? field : fd);

        const docRef = data(db, "settings", "fieldData");
        return setDoc(docRef, updatedFieldData);
    }
};

const addColumnNames = (uid, columnNames) => {
    if(columnNames.length === 0) return;
    const docRef = doc(db, "columnNames", uid);
    updateDoc(docRef, {
        columnNames: arrayUnion(...columnNames),
    });
};

const getColumnNames = uid => {
    const docRef = doc(db, "columnNames", uid);
    return getDoc(docRef);
};

// const bisratsUid = "qH1ghgo1OldJ4KuzkpTwJhHjtLh1";
// addColumnNames(bisratsUid, ["sim", "edme", "kumet", "wufret"]);

const addFieldToFirestore = fieldDatum => {
    const docRef = doc(db, "settings", "fieldData");
    return updateDoc(docRef, {
        fieldNames: arrayUnion(fieldDatum)
    });
};

const removeField = fieldDatum => {
    const docRef = doc(db, "settings", "fieldData");
    return updateDoc(docRef, {
        fieldNames: arrayRemove(fieldDatum)
    });
};

const subscribeToFieldNameChanges = callback => {
    const docRef = doc(db, "settings", "fieldData");
    return onSnapshot(docRef, doc => {
        const fieldNames = doc.data().fieldNames;
        callback(fieldNames);
    });
};

const addEntry = entry => {
    const collectionRef = collection(db, "entries");
    return addDoc(collectionRef, { ...entry, createdAt: serverTimestamp(), updatedAt: serverTimestamp() });
};

const updateEntry = (docId, key, value) => {
    const docRef = doc(db, "entries", docId);
    return updateDoc(docRef, {
        [key]: value,
        updatedAt: serverTimestamp(),
    });
};

const mergeEntries = (deleteEntries, mergedEntry, newDatabaseName, uid) => {
    const batch = writeBatch(db);
    // add newDatabaseName to the array of databases the user has
    const dbArrayRef = doc(db, "db_list", uid);
    batch.update(dbArrayRef, { list: arrayUnion(newDatabaseName) });

    deleteEntries.forEach(entry => {
        if(entry.id){
            const docRef = doc(db, "doc_entries", entry.id);
            batch.delete(docRef);
        }
    });
    
    // clean up undefined values
    cleanUpUndefined(mergedEntry);

    // generate id
    const docId = generateId();
    // doc reference
    const docRef = doc(db, "doc_entries", docId);
    batch.set(docRef, { ...mergedEntry, database: newDatabaseName } );

    return batch.commit();
};

const appendToArray = (collectionName, docId, arrayName, data) => {
    const docRef = doc(db, collectionName, docId);
    return updateDoc(docRef, {
        [arrayName]: arrayUnion(data)
    });
};

const removeFromArray = (collectionName, docId, arrayName, data) => {
    const docRef = doc(db, collectionName, docId);
    return updateDoc(docRef, {
        [arrayName]: arrayRemove(data)
    });
};

const getDbList = async(uid) => {
    const docRef = doc(db, "db_list", uid);
    return (await getDoc(docRef)).data();
};


const deleteDatabase = (uid, databaseName) => {
    return new Promise(async(res, rej) => {
        try{
            const entries = await getAllEntries(uid, databaseName);
            const promises = entries.map(entry => {
                const docRef = doc(db, "doc_entries", entry.id);
                return deleteDoc(docRef);
            });
            promises.push(removeFromArray("db_list", uid, "list", databaseName));
            const result = await Promise.all(promises);

            // // since all database entries were deleted without any problems, we can proceeed to delete the database name from the database array
            // await removeFromArray("db_list", uid, "list", databaseName);

            res(result);
        }
        catch(error){
            rej(error);
        }
    });
};

export {
    addToCollection,
    addField,
    removeField,
    subscribeToFieldNameChanges,
    addEntry,
    updateEntry,
    writeArrayToFirestore,
    getEntries,
    getAllEntries,
    mergeEntries,
    getDbList,
    deleteDatabase,
    addColumnNames,
    getColumnNames,
};