import React, { useState } from "react";
import withLogin from "./auth_components/withLogin";

// function Sandbox({columnNames, rows}){
function Sandbox(){
    const firstArray = ['String 1', 'String 2', 'String 3'];
    const secondArray = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

    const [selectValues, setSelectValues] = useState(Array(firstArray.length).fill(null));

    const handleSelectChange = (e, i) => {
        const value = e.target.value;
        setSelectValues(sv => {
            const newSelectedValues = [...sv];
            newSelectedValues[i] = value;
            return newSelectedValues;
        });
    };

    const getOptions = i => {
        const difference = secondArray.filter(e => !selectValues.includes(e));
        return selectValues[i] ? [selectValues[i], ...difference] : difference;
    };
    
    return (
        <div style={{width: "300px"}}>{
            firstArray.map((fae, i) => (
            <div key={i} className="flex justify-between px-2 py-1 mx-2 my-1">
                <p>{fae}</p>
                <div>
                    <select value={selectValues[i] || ""} onChange={e => handleSelectChange(e, i)}>
                        <option disabled value="">Map to</option>
                        { getOptions(i).map((sae, j) => <option key={j} value={sae}>{sae}</option>) }
                    </select>
                    <button onClick={() => setSelectValues(sv => {
                        const newSelectedValues = [...sv];
                        newSelectedValues[i] = null;
                        return newSelectedValues;
                    })}>clear</button>
                </div>
            </div>))
        }</div>
    );
}

export default withLogin(Sandbox);