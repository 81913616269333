import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../../contexts/AuthContext";
import { getAuth, RecaptchaVerifier, ConfirmationResult } from "firebase/auth";
import OTPInput from "react-otp-input";

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

function Login(){

    const [loading, setLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [OTP, setOTP] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { sendCodeToPhoneNumber, currentUser } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        
        if(currentUser){
            navigate("/");
            return;
        }

        if(!window.recaptchaVerifier){
            const auth = getAuth();
            
            window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container",
                {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log("captcha is solved");
                    },
                    'expired-callback': () => {
                        setErrorMessage("reCAPTCHA expired, solve the reCAPTCHA again");
                    }
                }
            );
    
            window.recaptchaVerifier.render();
        }

    }, []);

    const getCode = async() => {
        try{
            setLoading(true);
            window.confirmationResult = await sendCodeToPhoneNumber(phoneNumber, window.recaptchaVerifier);
            setCodeSent(true);
        }
        catch(error){
            console.log("error: ", error);
            setErrorMessage(`Failed to send code to ${phoneNumber}`);
        }
        finally{
            setLoading(false);
        }
    };

    const onOTPEnter = async() => {
        try{
            setLoading(true);
            const result = await window.confirmationResult.confirm(OTP);
            navigate("/");
        }
        catch(error){
            setErrorMessage("Code Not Correct");
        }
        finally{
            setLoading(false);
        }
    };

    return (<div className="h-full flex justify-center items-center">
        <div className="bg-groupa1 rounded">
            <div className="w-full text-center text-4xl font-bold p-3 rounded-t-lg bg-groupa3 text-groupa1">
                { codeSent ? "Enter OTP" : "Verify Phone Number" }
            </div>
            {errorMessage ? <div className="w-full text-center bg-red-200 italic text-sm">{errorMessage}</div> : null}

            { codeSent ? 
            <div className="p-3">
                <div className="my-2">
                    <label htmlFor="otp">OTP</label>
                    <br />
                    <OTPInput
                        id="otp"
                        numInputs={6}
                        value={OTP}
                        onChange={setOTP}
                        renderSeparator={<span className="text-groupa3 text-xl">-</span>}
                        renderInput={(props) => <input {...props} style={{width: "3em", textAlign: "center", padding: ".5em", borderRadius: ".25em"}} />}/>
                </div>
                <div
                    id="recaptcha-container"
                    className="inline-block text-sm text-red-800 italic max-w-xs">
                </div>
                <div className="w-full text-center">
                    <button
                        type="button"
                        className="w-full bg-groupa3 text-white font-bold px-3 py-2 mt-1 rounded"
                        onClick={onOTPEnter}>
                        { loading ? <BeatLoader size={8} style={{display: "inline-block"}} /> : "Login" }
                    </button>
                </div>
            </div>:

            <div className="p-3">
                <div className="my-2">
                    <label htmlFor="phone_number">Phone Number</label>
                    <br />
                    <PhoneInput
                        className="w-full"
                        value={phoneNumber}
                        inputProps={{style: {width: "100%"}}}
                        onChange={setPhoneNumber}/>
                </div>
                <div id="recaptcha-container" className="inline-block"></div>
                <div className="w-full text-center">
                    <button
                        type="button"
                        className="w-full bg-groupa3 text-white font-bold px-3 py-2 mt-1 rounded"
                        onClick={getCode}>
                        { loading ? <BeatLoader size={8} style={{display: "inline-block"}} /> : "Get Login Code" }
                    </button>
                </div>
            </div>}

        </div>
    </div>);
};

export default Login;