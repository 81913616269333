import React, { useState } from "react";
import { AiOutlineCopy, AiOutlineEdit } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import DataWrapper from "./DataWrapper";
import { numberToAlphabet } from "../../helper_functions/parsers";

function Table({columnNames, rows, editColumn = false, onEditColumn = () => {}}){
    const allColumns = columnNames.length > 0 ? ["No", ...columnNames] : [];
    const allRows = rows.length > 0 ? rows.map((row, index) => [index + 1, ...row]) : [];
    const [idCollapsed, setIdCollapsed] = useState(true);
    const [collectorIdCollapsed, setCollectorIdCollapsed] = useState(true);

    return (<div>
        <Toaster />
        <table className="w-full">
            <thead className="bg-gray1 text-white border-b-2 border-gray-200">
                <tr>{
                    allColumns.map((cn, j) => (
                        <th
                            key={j}
                            className="p-3 font-normal tracking-wide text-left">
                                {j > 0 ? <span className="px-6">{numberToAlphabet(j-1)}</span> : ""}  
                        </th>
                    ))
                }</tr>
                <tr>{
                    allColumns.map((cn, j) => (
                    <th
                        key={j}
                        className="p-3 font-bold tracking-wide text-left">
                            <div className="group">
                                <div
                                    className="hidden absolute group-hover:block whitespace-nowrap">
                                    <button onClick={() => {
                                        try{
                                            navigator.clipboard.writeText(cn);
                                            toast.success("Successfully copied!");
                                        }
                                        catch{
                                            toast.error("Failed to copy");
                                        }
                                    }}>
                                        <AiOutlineCopy className="inline-block" />
                                    </button>
                                    {
                                        (editColumn && cn !== "No") ?
                                        <button onClick={() => onEditColumn(cn)}>
                                            <AiOutlineEdit className="inline-block" />
                                        </button> : ""
                                    }
                                </div>
                                <div className="py-2"
                                    onClick={() => {
                                        if(cn === "id") setIdCollapsed(ic => !ic);
                                        if(cn === "collectorId") setCollectorIdCollapsed(cic => !cic);
                                    }}>
                                    <DataWrapper str={cn} />
                                </div>
                            </div>
                    </th>))
                }</tr>
            </thead>
            <tbody>{allRows.map((row, i) => {
                return <tr
                    key={row + i}
                    className={i % 2 === 0 ? "bg-white" : "bg-gray-100"}>{
                    row.map((data, k) =>
                        {
                            const ellipsis = (allColumns[k] === "id" && idCollapsed) || (allColumns[k] === "collectorId" && collectorIdCollapsed);

                            return (<td
                                key={data + (i + k) * (i+1)}
                                className="text-sm text-gray-600 p-3 text-gray-700 whitespace-nowrap"
                                style={{backgroundColor: data ? "white" : "rgba(170, 170, 170, .5)", margin: data ? "0px" : ".5em 1em"}}>
                                <div className="group">
                                    <div className="hidden absolute group-hover:block">
                                        <button
                                            onClick={() => {
                                                try{
                                                    navigator.clipboard.writeText(data);
                                                    toast.success("Successfully copied!");
                                                }
                                                catch{
                                                    toast.error("Failed to copy");
                                                }
                                            }}>
                                            <AiOutlineCopy className="inline-block" />
                                        </button>
                                    </div>
                                    <div style={{overflow: "hidden", width: ellipsis ? "24px" : "auto"}} className={`grow-shrink ${ellipsis ? "shrink" : "grow"}`}>
                                        {ellipsis ? <p className="text-gray-700 text-right w-full">...</p> : <DataWrapper str={data} />}
                                    </div>
                                </div>
                        </td>)
                    })
                }</tr>
            })}</tbody>
        </table>
    </div>);
}

export default Table;