import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getDbList, getEntries } from "../firebase/firestore";
import { downloadObjectAsCsv, downloadObjectAsJson, jsonToTableData } from "../helper_functions/parsers";
import { useAuth } from "../contexts/AuthContext";

import Table from "./helper_components/Table";
import AddModal from "./modals/AddModal";
import { Toaster, toast } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import withLogin from "./auth_components/withLogin";

import { AiOutlineDelete, AiFillPlusCircle } from "react-icons/ai";
import DeleteModal from "./modals/DeleteModal";

let lastDoc = null;
let scrollTop;
const paginationData = {
    loading: false,
    allLoaded: false,
};

function Entries(){

    const [entries, setEntries] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [fetchCount, setFetchCount] = useState(0);

    const [DBs, setDBs] = useState([]);
    const [selectedDB, setSelectedDB] = useState("Choose Database");

    const divRef = useRef(null);

    const location = useLocation();

    const { currentUser } = useAuth();

    const populateEntries = async(databaseName) => {
        if(paginationData.allLoaded || paginationData.loading || databaseName.length === 0 || databaseName === "Choose Database") return;
        try{
            setLoading(true);
            paginationData.loading = true;
            const { tableObject, newLastDoc, empty } = await getEntries(currentUser.uid, databaseName, lastDoc);
            setEntries(oldTableObject => [...oldTableObject, ...tableObject]);
            lastDoc = newLastDoc;
            if(empty) paginationData.allLoaded = true;
        }
        catch(error){
            console.log("error: ", error);
            toast.error("Failed to load data");
        }
        finally{
            setLoading(false);
            paginationData.loading = false;
        }
    };

    const fetchData = async() => {
        const { pathname } = location;
        if(pathname === "/"){
            if(entries.length === 0){
                if(!paginationData.loading){
                    try{
                        const result = await getDbList(currentUser.uid);
                        const dbList = result?.list;
                        if(!dbList) return;
                        setDBs(dbList);

                        if(dbList.length > 0){
                            setSelectedDB(dbList[0]);
                            populateEntries(dbList[0]);
                        }
                        else{
                            toast("No databases found!\n\nYou can create one by clicking on the \"+\" button.")
                        }
                    }
                    catch(error){
                        console.log("error: ", error);
                        toast.error("Network Error");
                    }
                }
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [location]);

    useEffect(() => {
        lastDoc = null;
        paginationData.allLoaded = false;
        setEntries([]);
        populateEntries(selectedDB);
    }, [selectedDB]);

    useEffect(() => {
        if(fetchCount === 0) return;

        lastDoc = null;
        paginationData.allLoaded = false;
        setColumnNames([]);
        setRows([]);
        setEntries([]);
        setSelectedDB("");

    }, [fetchCount]);

    useEffect(() => {
        const [rs, cns] = jsonToTableData(entries);
        setColumnNames(cns);
        setRows(rs);
    }, [entries]);
    

    useEffect(() => {
        const divElement = divRef.current;
        scrollTop = divElement.scrollTop;

        const handleScroll = (event) => {

            const divElement = divRef.current;
            const isAtBottom = (divElement.scrollHeight - divElement.scrollTop) === divElement.clientHeight;
            
            let isHorizontal = false;
            if(scrollTop === divElement.scrollTop){
                isHorizontal = true;
            }
            scrollTop = divElement.scrollTop;

            if (isAtBottom && !isHorizontal && !loading){
                populateEntries(selectedDB);
            }
        };

        if (divElement) {
            divElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="p-5">
            <AddModal isOpen={isAddModalOpen} onRequestClose={() => setIsAddModalOpen(false)} setFetchCount={setFetchCount}/>
            <DeleteModal isOpen={isDeleteModalOpen} onRequestClose={() => setIsDeleteModalOpen(false)} setFetchCount={setFetchCount}/>
            <div className="fixed right-2 bottom-2">
                <button onClick={() => setIsAddModalOpen(true)}>
                    <p style={{fontSize: "1.5em", color: "#0163F7",}} className="transition duration-300 hover:text-green-400">
                        <AiFillPlusCircle/>
                    </p>
                </button>
                <button onClick={() => setIsDeleteModalOpen(true)}>
                    <p style={{fontSize: "1.5em", color: "#e71228",}} className="transition duration-300 hover:text-green-400">
                        <AiOutlineDelete/>
                    </p>
                </button>
            </div>
            <div
                style={{maxHeight: `${document.body.clientHeight * 0.9}px`, overflowY: "scroll", overflowX: "auto"}}
                ref={divRef}>
                <Toaster />
                <div className="bg-gray-200 p-4">
                    <select
                        className="block min-w-32 px-2 py-1 bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                        value={selectedDB}
                        disabled={loading}
                        onChange={e => setSelectedDB(e.target.value)}>
                        
                        <option disabled selected value="">Select a database</option>
                        { DBs.length === 0 ? <option disabled >No Database found</option> : ""}
                        { DBs.map((dbName, i) => <option key={i} value={dbName}>{dbName}</option>) }
                    </select>
                </div>
                {
                    entries.length > 0 ?
                    <button
                        className="bg-myblue text-white font-bold px-2 py-1"
                        disabled={loading}
                        onClick={() => downloadObjectAsJson(entries)}>
                            Download data as JSON
                    </button> : ""
                }
                {
                    entries.length > 0 ?
                    <button
                        className="bg-myblue text-white font-bold px-2 py-1 ml-2"
                        disabled={loading}
                        onClick={() => downloadObjectAsCsv(entries)}>
                            Download data as CSV
                    </button> : ""
                }
                <div className="mt-4">
                    <Table columnNames={columnNames} rows={rows}/>
                </div>
                {
                    loading ? 
                        <div className="flex justify-center items-center">
                            <BeatLoader size={8}/>
                        </div> : ""
                }
            </div>
        </div>);
}

export default withLogin(Entries);