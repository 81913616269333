import { initializeApp, } from "firebase/app";
import { getAuth } from "firebase/auth";
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/compat/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCelz44bXKeIJqppDC9RLlFEY3YE74OuQI",
  authDomain: "campaign-1e935.firebaseapp.com",
  projectId: "campaign-1e935",
  storageBucket: "campaign-1e935.appspot.com",
  messagingSenderId: "278021382039",
  appId: "1:278021382039:web:f00ddc06eab4e168e2aed2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;