import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import useGlobalStore from "../../stores/global_store";
import { useAuth } from "../../contexts/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import {
    parseJSON,
    getJSONFromCSV,
    jsonToTableData,
    changeKeys,
    processData,
    round,
    removeArrayElements,
} from "../../helper_functions/parsers";

import { addColumnNames, getDbList, mergeEntries, writeArrayToFirestore } from "../../firebase/firestore";

import Table from "../helper_components/Table";
import Table2 from "../helper_components/Table2";
import Conflict from "../helper_components/Conflict";
import MapModal from "./MapModal";

function AddModal({isOpen, onRequestClose, setFetchCount}){

    const [columnNames, setColumnNames] = useState([]);
    const [rows, setRows] = useState([]);
    const [colName, setColName] = useState("");
    const [previousColName, setPreviousColName] = useState("");
    const [uploading, setUploading] = useState(false);
    const [editColNameModalIsOpen, setEditColNameModalIsOpen] = useState(false);
    const [mapModalIsOpen, setMapModalIsOpen] = useState(false);

    const [processText, setProcessText] = useState("Process");
    const [processed, setProcessed] = useState(false);
    const [sliderValue, setSliderValue] = useState(0.75);
    const [checkedColumns, setCheckedColumns] = useState([]);
    const [displayProcessedData, setDisplayProcessedData] = useState(false);
    const [numberOfConflicts, setNumberOfConflicts] = useState(0);
    const [conflictRows, setConflictRows] = useState([]);
    const [cleanRows, setCleanRows] = useState([]);
    const [conflictResolutions, setConflictResolutions] = useState([]);
    const [omitIndices, setOmitIndices] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const [DBs, setDBs] = useState([]);
    const [selectedDB, setSelectedDB] = useState("");
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [newDatabaseNameError, setNewDatabaseNameError] = useState("");
    const [newDatabaseName, setNewDatabaseName] = useState("");
    const [useExisting, setUseExisting] = useState(false);

    const [allChecked, setAllChecked] = useState(false);

    const tableJson = useGlobalStore(state => state.tableJson);
    const setTableJson = useGlobalStore(state => state.setTableJson);
    const { setAllEntries } = useGlobalStore(state => state.setAllEntries);

    const uploadRef = useRef(null);
    const colNameRef = useRef(null);

    const { currentUser } = useAuth();

    const fetchDBs = async() => {
        try{
            const result = (await getDbList(currentUser.uid));
            const dbList = result?.list
                ?.filter(e => e.length > 0);
            if(!dbList) return;
            setDBs(dbList);
            if(dbList.length > 0){
                setUseExisting(true);
            }
        }
        catch(error){
            console.log("error: ", error);
            toast.error("Network Error");
        }
    };

    useEffect(() => {
        fetchDBs();
    }, []);

    useEffect(() => {
        if(tableJson){
            const [rows, columnNames]  = jsonToTableData(tableJson);
            setColumnNames(columnNames);
            setCheckedColumns(columnNames.map(_ => false));
            setRows(rows);
        }
    }, [tableJson]);

    useEffect(() => {
        if(colName.length > 0){
            colNameRef?.current?.focus?.();
        }
    }, [colName]);

    const handleFileInputChange = async(e) => {
        const file =  e.target.files[0];
        if(!file) return;
        
        const ext = file.name.split(".").pop().toLowerCase();

        try{
            let result;
            if(ext !== "json" && ext !== "csv"){
                toast.error("Only JSON and CSV files allowed!");
                return;
            }
            
            setTableJson([]);
            setProcessed(false);
            setDisplayProcessedData(false);

            if(ext === "json") result = await parseJSON(file);
            else if(ext === "csv") result = await getJSONFromCSV(file);
            setTableJson(result);
        }
        catch(error){
            toast.error(`Error parsing file: ${file.name} is not properly formatted`);
        }
    };

    const handleProcessingResult = result => {
        const { cleanRows, conflictRows } = result;
        setCleanRows(cleanRows);
        setConflictRows(conflictRows);
        setConflictResolutions(conflictRows.map(_ => null));

        setNumberOfConflicts(conflictRows.length);
        setDisplayProcessedData(true);
    };

    const validateDatabaseName = value => {
        let isValid = false;
        let error = "";
        if(value.length === 0) {
            error = "Database name cannot be empty!";
        }
        else if(DBs.includes(value)){
            error = "Database name already taken";
        }
        else if(/[^a-zA-Z0-9 ]/.test(value)){
            error = "Only letters, numbers and spaces allowed";
        }
        else{
            isValid = true;
        }
        return { error, isValid };
    };

    const handleNewDatabaseNameChange = e => {
        const value = e.target.value;
        setNewDatabaseName(value);
        const { isValid, error } = validateDatabaseName(value);
        setNewDatabaseNameError(error);
        setAllowSubmit(isValid);
    };

    const handleSubmit = async() => {
        setSubmitting(true);
        const unprocessedRows = useExisting ? cleanRows : tableJson;

        try{
            // add the new column names
            addColumnNames(currentUser.uid, columnNames);
            for(let i = 0; i < conflictRows.length; i++){
                const { dbRows } = conflictRows[i];
                const omitIndexes = omitIndices[i];
                const deletableRows = removeArrayElements(dbRows, omitIndexes);
                if(conflictResolutions[i]){
                    await mergeEntries(deletableRows, conflictResolutions[i], useExisting ? selectedDB : newDatabaseName, currentUser.uid);
                }
            }
            setConflictRows([]);
            setConflictResolutions([]);
            await writeArrayToFirestore(unprocessedRows, currentUser.uid, useExisting ? selectedDB : newDatabaseName);
            toast.success("Entries successfully written");
            setNewDatabaseName("");
            setCleanRows([]);
            setTableJson([]);
            setProcessed(false);
            setDisplayProcessedData(false);
            setFetchCount(fc => fc + 1);
            onRequestClose();
            window.location?.reload?.();
        }
        catch(error){
            toast.error("Something went wrong while submitting");
            console.log("error: ", error);
        }
        finally{
            setSubmitting(false);
        }
    };

    const handleOnUseExisting = e => {
        const checked = e.target.checked;
        setUseExisting(checked);
        if(checked){
            setAllowSubmit(selectedDB.length > 0);
        }
        else{
            const nameStatus = validateDatabaseName(newDatabaseName);
            const { isValid, error = "" } = nameStatus;
            setAllowSubmit(isValid);
            setNewDatabaseNameError(error);
        }
    };

    const handleOnProcess = async() => {
        if(selectedDB.length === 0){
            toast.error("You must choose a database");
            return;
        }
        setUploading(true);
        const atLeastOneChecked = checkedColumns.reduce((acc, e) => acc ||= e, false);
        if(!atLeastOneChecked){
            toast.error("You must choose at least one column");
            return;
        }
        try{
            setProcessText("Fetching data...");
            const compareColumnNames = columnNames.filter((_, i) => checkedColumns[i]);
            // console.log("comparedColumns: ", compareColumnNames);
            const r = await processData(
                currentUser.uid,
                tableJson,
                compareColumnNames,
                selectedDB,
                p => setProcessText(`Processing ${round(p * 100, 1)}%`),
                parseFloat(sliderValue),
            );
            // console.log("r: ", r);
            // const { cleanRows, conflictRows } = r;
            // for(let i = 0; i < cleanRows.length; i++) console.log(`${Number(i).toString().padStart(2, 0)} - ${cleanRows[i].categoryName}`)
            // for(let i = 0; i < conflictRows.length; i++) console.log(`${Number(i).toString().padStart(2, 0)} - ${conflictRows[i].categoryName}`)
            
            if(r.networkError){
                toast.error("Network Error!");
                console.log("networkError");
            }
            setProcessText("Process");
            handleProcessingResult(r);
            setProcessed(true);
        }
        catch(error){
            console.log("error: ", error);
            toast.error("Network error!");
        }
        finally{
            setUploading(false);
        }
    };

    const dbListEmpty = DBs.length === 0;
    const showProcessMenu = useExisting && tableJson.length > 0 && !dbListEmpty;
    const disableSubmitButton = submitting || !allowSubmit || (useExisting && !processed);

    return (<Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Toaster />
        <MapModal isOpen={mapModalIsOpen} onRequestClose={() => setMapModalIsOpen(false)} fileColumnNames={columnNames} />
        <Modal isOpen={editColNameModalIsOpen} onRequestClose={() => setEditColNameModalIsOpen(false)}>
            <h1 className="text-xl p-2">Edit Column Name</h1>
            <button
                onClick={() => setEditColNameModalIsOpen(false)}
                className="absolute top-2 right-2 flex justify-center items-center w-6 h-6 rounded font-bold transition duration-300 hover:bg-gray-100">X</button>
            <div className="p-2">
                <p>Previous column name: <span className="italic">{previousColName}</span></p>
                <input
                    ref={colNameRef}
                    type="text"
                    className="border-2 border-green-500/50 focus:outline-green-500/50 px-3 py-1"
                    placeholder="New column name"
                    value={colName}
                    onChange={e => setColName(e.target.value)} />
                <br />
                <button
                    className="bg-green-600 text-white px-2 py-1 my-2 w-24 font-bold"
                    onClick={() => {
                        // const tableJsonShallowCopy = [ ...tableJson ];
                        // changeKeys(tableJsonShallowCopy, previousColName, colName);
                        const newTableJson = changeKeys(tableJson, previousColName, colName);
                        setTableJson(newTableJson);
                        setEditColNameModalIsOpen(false);
                    }}>
                    Change
                </button>
            </div>
        </Modal>
        
        <div className="grid">
            <div>
                <input
                    ref={uploadRef}
                    type="file"
                    accept=".csv,.json"
                    onChange={handleFileInputChange}
                    style={{display: "none"}}/>
                <button
                    className="bg-myblue text-white font-bold px-2 py-1 w-28"
                    onClick={() => uploadRef.current.click()}>
                        Choose File
                </button>
                <button
                    className="bg-myred text-white font-bold px-2 py-1 ml-2 w-28"
                    onClick={() => onRequestClose()}>
                        Cancel
                </button>
            </div>

            {tableJson.length > 0 ? 
                <div className="my-2 bg-gray-200 p-2">
                    <div>
                        <button className="px-2 py-1 bg-green-600 text-slate-50 font-bold" onClick={() => setMapModalIsOpen(true)}>Map</button>
                    </div>
                    <div>
                        <label className={`flex items-center space-x-2 ${dbListEmpty ? 'text-gray-400 cursor-not-allowed' : ''}`}>
                            <input
                                checked={useExisting}
                                onChange={handleOnUseExisting}
                                type="checkbox"
                                className={`form-checkbox h-4 w-4 ${dbListEmpty ? 'text-gray-400' : 'text-gray-700'}`}
                                disabled={dbListEmpty || submitting}
                            />
                            <span className={`text-gray-700 ${dbListEmpty ? 'text-gray-400' : ''}`}>Add to existing database </span>
                        </label>
                    </div>
                    {dbListEmpty ? "" :
                        <div className="bg-gray-200 my-2 h-10" style={{display: useExisting ? "block" : "none"}}>
                            <select
                                className="block h-10 w-52 px-2 py-1 bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                value={selectedDB}
                                disabled={!useExisting}
                                onChange={e => {
                                    const value = e.target.value;
                                    setSelectedDB(value);
                                    if(value?.length > 0 && useExisting) setAllowSubmit(true);
                                }}>
                                <option disabled defaultValue={"Select a database"} value="">Select a database</option>
                                { DBs.length === 0 ? <option disabled >No Database found</option> : ""}
                                { DBs.map((dbName, i) => <option key={i} value={dbName}>{dbName}</option>) }
                            </select>
                            <div className="h-8"></div>
                        </div>
                    }
                    <div className="my-2 h-12" style={{display: (useExisting && !dbListEmpty) ? "none" : "block"}}>
                        <input  
                            type="text"
                            value={newDatabaseName}
                            onChange={handleNewDatabaseNameChange}
                            className="h-10 w-52 appearance-none bg-gray-200 text-gray-700 border-2 border-green-400 py-2 px-4 outline-green-400 leading-tight focus:bg-white focus:border-gray-500"
                            placeholder="New Database Name..."
                        />
                        <br />
                        <div className="h-8">
                            <label className="text-sm text-red-400">{newDatabaseNameError}</label>
                        </div>
                    </div>
                    <div className="my-1 bg-gray-200 py-2">
                        <button
                            className="text-xl text-white font-bold px-3"
                            style={{backgroundColor: disableSubmitButton ? "#E5E7EB" : "#04AA6D", border: disableSubmitButton ? "2px solid #ccc" : "none", color: disableSubmitButton ? "#ccc" : "white"}}
                            disabled={disableSubmitButton}
                            onClick={handleSubmit}>
                            Submit{submitting ? "ting" : ""}
                        </button>
                    </div>
                </div>
            : ""}
            
            <div className={`bg-gray-200 p-2 w-full ${showProcessMenu ? "show" : "hide"}`} style={{display: showProcessMenu ? "block" : "none"}}>
                <h1 className="text-xl font-bold p-1">Choose columns to compare</h1>
                <div className="my-2">
                    <span className="p-2 mr-2">Similarity index</span>
                    <input
                        style={{background: "red", color: "red"}}
                        type="range"
                        min={0.5}
                        max={1}
                        step={0.01}
                        value={sliderValue}
                        onChange={e => setSliderValue(e.target.value)}
                    />
                    <span>{sliderValue}</span>
                </div>
                <div>
                    <button
                        className="px-2 py-1 bg-gray-400"
                        onClick={() => {
                                setCheckedColumns(ccs => allChecked ? ccs.map(_ => false) : ccs.map(_ => true));
                                setAllChecked(ac => !ac);
                            }}>
                            {allChecked ? "Uncheck all" : "Check all"}
                    </button>
                </div>
                {columnNames
                    .filter(columnName => columnName !== "collectorId")
                    .map((columnName, i) => (
                        <span key={i} className="p-1 whitespace-nowrap">
                            <input
                                type="checkbox"
                                className="mr-1"
                                checked={checkedColumns[i]}
                                onChange={e => setCheckedColumns(checkedColumns => {
                                    const newCheckedColumns = [...checkedColumns];
                                    newCheckedColumns[i] = e.target.checked;
                                    return newCheckedColumns;
                                })}/>
                            <span>{columnName}</span>
                        </span>))}
                <br />
                <button
                    className="bg-myblue text-white font-bold px-2 py-1"
                    onClick={handleOnProcess}>
                    {processText}
                </button>
            </div>

            <div style={{display: displayProcessedData ? "block" : "none"}}>
                <p className="bg-red-400 p-2">
                    {numberOfConflicts === 0 ? "No" : numberOfConflicts} conflict{numberOfConflicts === 1 ? "" : "s"} found
                </p>
                <div className="mt-5">{
                    conflictRows.map((conflictRow, i) => (
                        <div className="mb-12">
                            <div className="p-0.5 bg-gray-600"></div>
                            <Conflict
                                key={i}
                                conflictRow={conflictRow}
                                compareColumnNames={columnNames.filter((_, i) => checkedColumns[i])}
                                setSolution={result => setConflictResolutions(cr => {
                                    const newCR = [ ...cr ];
                                    newCR[i] = result;
                                    return newCR;
                                })}
                                setOmitIndices={omitIndexArray => setOmitIndices(ois => {
                                    const newOIs = [...ois];
                                    newOIs[i] = omitIndexArray;
                                    return newOIs;
                                })}/>
                            <div className="p-0.5 bg-gray-600"></div>
                        </div>))
                }</div>
                
                <p className="bg-green-400 p-2">
                    {cleanRows.length === 0 ? "No" : cleanRows.length} clean row{cleanRows.length === 1 ? "" : "s"} found
                </p>
                <div>
                    <Table2 tableData={cleanRows}/>
                </div>
            </div>
            <div style={{display: displayProcessedData ? "none" : "block"}}>
                <Table
                    rows={rows}
                    columnNames={columnNames}
                    editColumn={true}
                    onEditColumn={cn => {
                        setColName("");
                        setPreviousColName(cn);
                        setEditColNameModalIsOpen(true);
                    }}/>
            </div>
        </div>
    </Modal>);
}

export default AddModal;