import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import toast, { Toaster } from "react-hot-toast";
import { AiOutlineReload } from "react-icons/ai";

import { deleteDatabase, getDbList } from "../../firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { BeatLoader } from "react-spinners";

function DeleteModal({isOpen, onRequestClose, setFetchCount}){

    const [databases, setDatabases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedDB, setSelectedDB] = useState("");
    const [enteredDb, setEnteredDb] = useState("");
    const { currentUser } = useAuth();

    const fetchDatabases = async() => {
        try{
            setLoading(true);
            const result = await getDbList(currentUser.uid);
            if(result){
                setDatabases(result.list);
            }
        }
        catch(error){
            toast.error("Network Error");
        }
        finally{
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDatabases();
    }, []);

    const handleDeleteDatabase = async() => {
        try{
            setDeleting(true);
            await deleteDatabase(currentUser.uid, enteredDb);
            setEnteredDb("");
            fetchDatabases();
            setFetchCount(count => count + 1);
            window.location?.reload?.();
        }
        catch(error){

        }
        finally{
            setDeleting(false);
        }
    };

    const canDelete = (selectedDB === enteredDb) && enteredDb.length > 0;
    const optionClasses = "px-2";

    return (<Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Toaster />
        <div className="fixed right-2 top-2">
            <button onClick={onRequestClose}>X</button>
        </div>
        <div className="text-xl font-bold text-gray-700">Delete Database</div>
        
        <div className="mt-6">
            <select
                className="block min-w-32 px-2 py-1 bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                value={selectedDB}
                disabled={loading}
                onChange={e => setSelectedDB(e.target.value)}>
                
                <option disabled selected value="" className={optionClasses}>Select a database</option>
                { databases.length === 0 ? <option disabled className={optionClasses}>No Database found</option> : ""}
                { databases.map((dbName, i) => <option key={i} value={dbName} className={optionClasses}>{dbName}</option>) }
            </select>
            <button
                disabled={loading}
                className={`my-1${loading ? " rotating-element" : ""}`}
                onClick={fetchDatabases}>
                    <AiOutlineReload />
            </button>
        </div>
        <div style={{display: selectedDB.length > 0 ? "block" : "none"}}>
            <span>Enter the name of the database</span>
            <br />
            <input
                type="text"
                value={enteredDb}
                onChange={e => setEnteredDb(e.target.value)} className="border-2 border-gray-400" />
            <br />
            <button
                disabled={!canDelete}
                className={`px-2 py-1 my-1 text-slate-50 ${canDelete ? "bg-red-600" : "bg-gray-400"}`}
                onClick={handleDeleteDatabase}>
                    Delet{deleting ? "ing" : "e"}
                    <BeatLoader style={{display: deleting ? "inline-block" : "none", marginLeft: ".25em",}} size={8} color="white"/>
            </button>
        </div>
    </Modal>);
}

export default DeleteModal;