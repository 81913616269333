import { create } from "zustand";

const useGlobalStore = create((set, get) => ({
    fieldValues: [],
    tableJson: [],
    setFieldValues: fieldValues => set({fieldValues}),
    setTableJson: tableJson => set({tableJson}),
    allEntriesOutOfDate: true,
    setAllEntriesOutOfDate: allEntriesOutOfDate => set({allEntriesOutOfDate}),
    allEntries: [],
    setAllEntries: allEntries => set({allEntries}),
}));

export default useGlobalStore;