import React, { useState } from "react";
import withLogin from "./auth_components/withLogin";
import AddModal from "./modals/AddModal";

function Upload(){

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    return (<div className="p-5 inline-block">
        
        <AddModal isOpen={isAddModalOpen} onRequestClose={() => setIsAddModalOpen(false)} />
        <button onClick={() => setIsAddModalOpen(true)}>Add</button>
    </div>)
}

export default withLogin(Upload);