import React from "react";
import { getAllObjectUniqueKeys, jsonToTableData } from "../../helper_functions/parsers";
import Table from "./Table";

function Table2({tableData, ...otherData}){
    
    const [rows, columnNames]  = jsonToTableData(tableData);
    return <Table rows={rows} columnNames={columnNames} {...otherData} />;
}

export default Table2;