import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

function withLogin(Component){

    return function(){
        const auth = useAuth();
        const currentUser = auth?.currentUser;
        const logOut = auth?.logOut;

        return (currentUser ? 
            <div>
                <div className="fixed top-2 right-2 bg-gray-200 p-2 transition duration-300 hover:bg-gray-300">
                    <button onClick={logOut}>Logout</button>
                </div>
                <Component />
            </div> :
            <Navigate to="/login" />);
    }
}

export default withLogin;