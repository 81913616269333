import React, { useContext, useState, useEffect } from "react";

import Loading from "../components/helper_components/Loading";
import { auth } from "../firebase/firebase_config";
import { signInWithPhoneNumber, getAuth, RecaptchaVerifier, } from "firebase/auth";

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({children}){

    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(false);

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsub;
    }, []);
    
    const  sendCodeToPhoneNumber = (phoneNumber, verifier) => {
        return signInWithPhoneNumber(getAuth(), "+" + phoneNumber, verifier);
    };

    const logOut = () => {
        return auth.signOut();
    };

    const value = {
        currentUser,
        sendCodeToPhoneNumber,
        logOut,
    };

    return (<AuthContext.Provider value={value}>
        {loading ? <Loading /> : children}
    </AuthContext.Provider>);
}