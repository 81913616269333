import { useEffect } from "react";
import { Link } from "react-router-dom";
import './App.css';
import Upload from "./components/Upload";
import Login from "./components/auth_components/Login";
import Entries from "./components/Entries";
import Sandbox from "./components/Sandbox";

import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Modal from "react-modal";

Modal.setAppElement("#root");

function App() {

  return (
    <div className="h-full inline-block w-full">
        <Router>
          <AuthProvider>
            {/* <div className="h-12 bg-gray-400 text-slate-50 font-bold flex items-center p-5 inline-block w-full">
              <Link to="/">Home</Link>
              <Link to="/add" className="mx-4">Add Data</Link>
            </div> */}
            <Routes>
              <Route exact path="/" element={<Entries />} />
              {/* <Route exact path="/add" element={<Upload />} /> */}
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/sandbox" element={<Sandbox />} />
            </Routes>
          </AuthProvider>
        </Router>
    </div>
  );
}

export default App;