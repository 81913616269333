import csvtojson from "csvtojson";
import { getAllEntries } from "../firebase/firestore";

const getFileText = file => {
    const reader = new FileReader();
    return new Promise((res, rej) => {
        reader.onload = e => {
            const contents = e?.target?.result;
            if(contents) res(contents);
            else rej(contents);
        };
        reader.readAsText(file);
    });
};

const parseJSON = async(jsonFile) => {

    const fileText = await getFileText(jsonFile);
    return new Promise((res, rej) => {
        try{
            res(JSON.parse(fileText));
        }
        catch(error){
            rej(error);
        }
    });
};

const getAllObjectUniqueKeys = arr => {
    const keysObject = {};
    for(let obj of arr){
        for(let key in obj){
            if(!keysObject[key]) keysObject[key] = 1;
        }
    }
    return Object.keys(keysObject);
};

const objArrToSortedArrArr = (objArr, columnNames) => {
    const rows = [];
    for(let i = 0; i < objArr.length; i++){
        const data = objArr[i];
        rows[i] = [];
        for(let j = 0; j < columnNames.length; j++){
            const cm = columnNames[j];
            if(data[cm]) rows[i][j] = data[cm];
            else rows[i][j] = "";
        }
    }
    return rows;
};

const getJSONFromCSV = csvFile => {
    const reader = new FileReader();
    return new Promise((res, rej) => {
        reader.onload = e => {
            const fileContents = (e.target.result)?.trim();
            
            // testing the library
            csvtojson().fromString(fileContents)
                .then(r => res(r))
                .catch(err => rej(err));
        }
        reader.readAsText(csvFile);
    });
};

const jsonToTableData = JSONResult => {
    const columnNames = getAllObjectUniqueKeys(JSONResult).sort();
    const rows = objArrToSortedArrArr(JSONResult, columnNames);
    return [rows, columnNames];
};

const truncateString = (str, maxSize = 15) => str?.length > maxSize ? `${str.slice(0, maxSize)}...` : str;

const removeOuterQuotes = str => {
    if(/^(['"]).+\1/.test(str)){
        return str.slice(1, str.length - 1);
    }
    return str;
};

const changeKeys = (arr, oldKey, newKey) => {
    return arr.map(obj => {
        const newObj = {};
        const keys = Object.keys(obj);
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            if(key === oldKey){
                newObj[newKey] = obj[oldKey];
            }
            else{
                newObj[key] = obj[key];
            }
        }
        return newObj;
    });
};


const changeUnloopedMultipleKeys = (arr, changePairs) => {
    if(changePairs.length === 0) return [...arr];
    return arr.map(obj => {
        const newObj = {};
        const keys = Object.keys(obj);
        const changePairOldKeys = changePairs.map(cp => cp.oldKey);
        
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            const changePairIndex = changePairOldKeys.indexOf(key);
            if(changePairIndex !== -1){
                const changePair = changePairs[changePairIndex];
                const newKey = changePair.newKey;
                newObj[newKey] = obj[key];
            }
            else{
                newObj[key] = obj[key];
            }
        }

        return newObj;
    });
};

const changeLoopedMultipleKeys = (arr, changePairs) => {
    if(changePairs.length === 0) return [...arr];
    return arr.map(obj => {
        const newObj = {...obj};
        for(const changePair of changePairs){
            const temp = obj[changePair.oldKey];
            newObj[changePair.oldKey] = obj[changePair.newKey];
            newObj[changePair.newKey] = temp;
            return newObj;
        }
    })
};

const changeMultipleKeys = (arr, changePairs) => {
    
    const loopedMappings = getLoopedMappings(changePairs);
    const cleanLoopedMappings = extractLoopedMappings(loopedMappings);
    const unLoopedMappings = separateLoopedMappings(changePairs, loopedMappings);

    return changeLoopedMultipleKeys(
            changeUnloopedMultipleKeys(arr, unLoopedMappings),
            cleanLoopedMappings);
};

const getLoopedMappings = changePairs => {
    let allLoopedMappings = [];

    for(let i = 0; i < changePairs.length; i++){
        const newKey = changePairs[i].newKey;
        const oldKey = changePairs[i].oldKey;
        const newKeyMapsTo = changePairs.find(lm => lm.oldKey === newKey);
        if(newKeyMapsTo && (newKeyMapsTo.newKey === oldKey)){
            allLoopedMappings.push(changePairs[i]);
        }
    }

    return allLoopedMappings;
};

const extractLoopedMappings = allLoopedMappings => {
    const loopedMappings = [];
    const skipIndexes = [];

    for(let i = 0; i < allLoopedMappings.length; i++){
        if(skipIndexes.includes(i)) continue;
        loopedMappings.push(allLoopedMappings[i]);
        const skipIndex = allLoopedMappings.findIndex(alm => alm.oldKey === allLoopedMappings[i].newKey && alm.newKey === allLoopedMappings[i].oldKey);
        skipIndexes.push(skipIndex);
    }

    return loopedMappings;
};

const separateLoopedMappings = (changePairs, loopedMappings) => {
    return changePairs.filter(cp => {
        const exists = loopedMappings.find(lm => (cp.oldKey === lm.oldKey) && (cp.newKey === lm.newKey));
        return !exists;
    });
};

const alphaNumeric = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const generateId = (size = 20 ) => {
    let id = "";
    for(let i = 0; i < size; i++){
        id += alphaNumeric[Math.floor(Math.random() * alphaNumeric.length)];
    }
    return id;
};

const objArrToCSVString = objArr => {
    const columnNames = getAllObjectUniqueKeys(objArr);
    const header = columnNames
        .map(value => {
        const escaped = value.toString().replace(/"/g, '\\"');
        return `"${escaped}"`;
    }).join(",");
    const csvRows = [header];
    for(const obj of objArr){
        const rowStr = columnNames
            .map(columnName => obj[columnName] ? obj[columnName] : "")
            .map(value => {
                const escaped = value.toString().replace(/"/g, '\\"');
                return `"${escaped}"`;
            })
            .join(",");
        csvRows.push(rowStr);
    }
    return csvRows.join("\n");
};

const downloadObjectAsJson = objectData => {
    const jsonData = JSON.stringify(objectData, null, 2);
    stringToFileDownload(jsonData, "data.JSON", "application/json");
};

const downloadObjectAsCsv = objectData => {
    const csvData = objArrToCSVString(objectData);
    stringToFileDownload(csvData, "data.CSV", "text/csv");
};

const stringToFileDownload = (str, fileName, fileType) => {
    // Create a Blob object with the JSON data
    const blob = new Blob([str], { type: fileType });
    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
    // Create a new anchor element
    const element = document.createElement('a');
    // Set the URL as the href attribute
    element.setAttribute('href', url);
    // Set the download attribute with the desired filename
    element.setAttribute('download', fileName);
    // Hide the anchor element
    element.style.display = 'none';
    // Append the anchor element to the document body
    document.body.appendChild(element);
    // Trigger a click event on the element
    element.click();
    // Remove the element from the document body
    document.body.removeChild(element);
    // Release the URL object
    URL.revokeObjectURL(url);
};

function getBigrams(str) {
    const bigrams = new Set();
    for (let i = 0; i < str.length - 1; i += 1) {
      bigrams.add(str.substring(i, i + 2));
    }
    return bigrams;
}
  
function intersect(set1, set2) {
    return new Set([...set1].filter((x) => set2.has(x)));
}
  
const diceCoefficient = (str1, str2) => {
    if(!str1 || !str2) return 0;

    const bigrams1 = getBigrams(str1.toString().toLowerCase());
    const bigrams2 = getBigrams(str2.toString().toLowerCase());
    const result = (2 * intersect(bigrams1, bigrams2).size) / (bigrams1.size + bigrams2.size);
    return result;
};

const deepCompare = (obj1, obj2) => {
    if (obj1 === obj2) return true;
  
    // Check if both objects are objects and not null
    if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
      // Get the keys of obj1 and obj2
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      // Check if the number of properties is the same
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      // Check if all properties and their values are equal recursively
      for (let key of keys1) {
        if (!deepCompare(obj1[key], obj2[key])) {
          return false;
        }
      }
  
      // All properties and their values are equal
      return true;
    }
  
    // Objects are not equal
    return false;
};

const processData = async(
        uid,
        fileRowsUncleaned,
        columnNames,
        databaseName,
        progressCallback = () => {},
        matchThreshold = 0.8
)=> {
    return new Promise(async(res, rej) => {
        let dbRows;
        try{
            dbRows = await getAllEntries(uid, databaseName);
        }
        catch(error){
            rej({networkError: true, error});
        }
        const conflictRows = [];
        const cleanRows = [];
        // removing empties from file rows;
        const fileRows = fileRowsUncleaned.filter(fru => {
            const keys = Object.keys(fru);
            return keys.map(key => fru[key].length).reduce((acc, e) => acc += e, 0) > 0;
        });
        for(let i = 0; i < fileRows.length; i++){
            progressCallback(i / fileRows.length);
            const fileRow = fileRows[i];
            const rowConflicts = { fileRow, dbRows: [] }
            // for loop to go through all the columns of a row
            for(let k = 0; k < dbRows.length; k++){
                let isConflictRow = true;
                // for loop to go through all the rows of the database entries
                const dbRow = dbRows[k];
                for(let j = 0; j < columnNames.length; j++){
                    const columnName = columnNames[j];
                    if(columnName === "collectorId") continue;
                    const similarityIndex = diceCoefficient(dbRow[columnName], fileRow[columnName]);
                    if(similarityIndex < matchThreshold){
                        isConflictRow = false;
                        break;
                    }
                }
                if(isConflictRow){
                    rowConflicts.dbRows.push(dbRow);
                }
            }
            // if no matches found, the row is clean
            if(rowConflicts.dbRows.length > 0){
                conflictRows.push(rowConflicts);
            }
            else{
                cleanRows.push(fileRow);
            }
        }
        res({cleanRows, conflictRows});
    });
};

const removeArrayElements = (arr, indices) => {
    if(arr.length === 0) return [];
    const result = [];
    let j = 0;
    for(let i = 0; i < arr.length; i++){
        if(!indices.includes(i)){
            result[j++] = arr[i];
        }
    }

    return result;
};

const round = (number, digits = 2) => {
    const factor = Math.pow(10, digits);
    return Math.round(number * factor) / factor;
};

const numberToAlphabet = n => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const number = Math.floor(n / 26);
    const index = n % 26;
    return `${alphabet[index]} ${number === 0 ? "" : number}`;
};

const putFirst = (arr1, arr2) => {
    const arr = [...arr1];
    // for all the elements in arr2, if they exist in arr, we put them first
    const orderedUnion = [];
    for(const element of arr2){
        const index = arr.indexOf(element);
        if(index !== -1){
            orderedUnion.push(element);
            arr.splice(index, 1);
        }
    }
    return [...orderedUnion, ...arr ];
};

const cleanUpUndefined = obj => {
    const keys = Object.keys(obj);
    for(let i = 0; i < keys.length; i++){
        const key = keys[i];
        if(obj[key] === undefined){
            delete obj[key];
        }
    }
};

export {
    parseJSON,
    getAllObjectUniqueKeys,
    objArrToSortedArrArr,
    truncateString,
    removeOuterQuotes,
    getJSONFromCSV,
    jsonToTableData,
    generateId,
    changeKeys,
    downloadObjectAsJson,
    downloadObjectAsCsv,
    processData,
    round,
    removeArrayElements,
    numberToAlphabet,
    putFirst,
    cleanUpUndefined,
    changeMultipleKeys,
};