import React, { Fragment, useEffect, useState } from "react";
import { getAllObjectUniqueKeys, numberToAlphabet, putFirst } from "../../helper_functions/parsers";
import DataWrapper from "./DataWrapper";

const getCheckboxData = columnNames => {
    const data = {};
    columnNames.forEach(cn => data[cn] = { isFileRow: true, isDbRow: false, dbRowIndex: 0 });
    return data;
};

const getResult = (columnNames, checkboxData, dbRows, fileRow) => {
    
    const result = {};
    columnNames.forEach(cn => {
        if(checkboxData[cn].isDbRow){
            result[cn] = dbRows[checkboxData[cn].dbRowIndex][cn];
        }
        else if(checkboxData[cn].isFileRow){
            result[cn] = fileRow[cn];
        }
    });

    return result;
};

function Conflict({conflictRow, setSolution, setOmitIndices, compareColumnNames}){

    const { fileRow, dbRows } = conflictRow;

    const columnNames = getAllObjectUniqueKeys([...dbRows, fileRow]).filter(cn => cn !== "id" && cn !== "collectorId");
    const sortedColumnNames = putFirst(columnNames, compareColumnNames);

    const [result, setResult] = useState({});
    const [checkboxData, setCheckboxData] = useState(getCheckboxData(columnNames));
    const [omitRows, setOmitRows] = useState(new Set());
    const [collapse, setCollapse] = useState(true);
    
    useEffect(() => {
        setResult(fileRow);
        setSolution(fileRow);
        setOmitIndices([]);
    }, []);

    useEffect(() => {
        // if the result hasn't been set yet, return
        if(Object.keys(result).length === 0) return;
        
        // where there are empty columns in the file row, take column value from first db row that has a value if any
        // console.log("columnNames: ", columnNames);
        for(let i = 0; i < columnNames.length; i++){
            const columnName = columnNames[i];
            // we don't want to take the id value from a db as it's randomly generated
            if(columnName === "id") continue;

            // console.log(`${columnName}, ${fileRow[columnName]}`);
            // console.log(`columnName: ${columnName}, fileRow[columnName]: ${fileRow[columnName]}`);
            if(!fileRow[columnName]){
                // find the first column value from the dbRows
                let value, index = -1, found = false;
                for(let j = 0; j < dbRows.length; j++){
                    const row = dbRows[j];
                    if(row[columnName]){
                        found = true;
                        value = row[columnName];
                        console.log(`columnName: ${columnName}, value: ${value}`);
                        index = j;
                        break;
                    }
                }
                if(found){
                    setCheckboxData(data => {
                        const newData = {...data};
                        newData[columnName].isFileRow = false;
                        newData[columnName].isDbRow = true;
                        newData[columnName].dbRowIndex = index;
                        const newResult = getResult(columnNames, newData, dbRows, fileRow);
                        setResult(newResult);
                        setSolution(newResult);
                        return newData;
                    });
                }
            }
        }
    }, [result]);

    const handleOmitCheck = (e, index) => {
        const checked = e.target.checked;
        const newORS = new Set(omitRows);
        if(checked) newORS.add(index);
        else newORS.delete(index);
        setOmitRows(newORS);
        setOmitIndices(Array.from(newORS));
    };

    const handleCheckboxes = (e, fileOrDbRow, columnName, i, dbRows, fileRow) => {
        const checked = e.target.checked;
        if(fileOrDbRow === "fileRow"){
            setCheckboxData(data => {
                const newData = {...data};
                newData[columnName].isFileRow = checked;
                if(checked){
                    newData[columnName].isDbRow = false;
                }
                const newResult = getResult(columnNames, newData, dbRows, fileRow);
                setResult(newResult);
                setSolution(newResult);
                return newData;
            });
        }
        else if(fileOrDbRow === "dbRow"){
            setCheckboxData(data => {
                const newData = {...data};
                newData[columnName].isDbRow = checked;
                if(checked){
                    newData[columnName].isFileRow = false;;
                    newData[columnName].dbRowIndex = i;
                }
                const newResult = getResult(columnNames, newData, dbRows, fileRow);
                setResult(newResult);
                setSolution(newResult);
                return newData;
            });
        }
    };

    const allColumns = sortedColumnNames.length > 0 ? ["No", ...sortedColumnNames] : [];

    return (
        <div>
            <table style={{border: "1px solid rgba(.7, .7, .7, .25)"}}>
                <thead>
                    <tr>{allColumns.map((cn, i) => (
                        <th
                            key={i}
                            className="p-1 tracking-wide font-normal text-left">
                                <span className="px-6">{i > 0 ? numberToAlphabet(i-1) : ""}</span>
                        </th>))}
                    </tr>
                    <tr>{allColumns.map((cn, i) => (
                        <th
                            key={i}
                            className="p-1 font-bold tracking-wide text-left">
                                <DataWrapper str={cn} />
                        </th>))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-gray-200">
                        <td colSpan={allColumns.length} className="p-1 text-xl font-bold cursor-pointer" onClick={() => setCollapse(c => !c)}>
                            Result
                            <span className={`rotate ${collapse ? "rotate-90cw" : "rotate-90ccw"}`}>&#9660;</span>
                        </td>
                    </tr>
                    <tr className="bg-green-50">{
                        allColumns.map((cn, l) => (
                            <td key={l} className="text-sm p-1 text-gray-700 whitespace-nowrap text-left">
                                {result[cn] ? <DataWrapper str={result[cn]} /> : ""}
                            </td>))
                    }</tr>
                </tbody>
                <tfoot
                    className={`bg-gray-50 grow-shrink-height ${collapse ? "shrink-height" : "grow-height"}`}
                    >
                    <tr className="bg-gray-200">
                        <td colSpan={allColumns.length} className="p-1 text-xl font-bold">Row from file</td>
                    </tr>
                    <tr className="bg-gray-50">{
                        allColumns.map((cn, j) => (
                            <td
                                key={j}
                                className="text-sm p-1 text-gray-700 whitespace-nowrap"
                                style={{border: "1px dashed rgba(140, 140, 140, .25)", backgroundColor: (fileRow[cn] || cn === "No") ? "white" : "rgba(170, 170, 170, .5)"}}>
                                {fileRow[cn] ? <input type="checkbox" checked={checkboxData[cn].isFileRow} onChange={e => handleCheckboxes(e, "fileRow", cn, j, dbRows, fileRow)} /> : ""}
                                <br />
                                {fileRow[cn] ? <DataWrapper str={fileRow[cn]} /> : ""}
                            </td>))
                    }</tr>
                    <tr className="bg-gray-200 cursor-pointer">
                        <td colSpan={allColumns.length} className="p-1 text-xl font-bold text-gray-700 whitespace-nowrap">
                            Row{dbRows.length === 1 ? "" : "s"} from database
                        </td>
                    </tr>
                    {
                        dbRows.map((dbRow, k) => (
                            <Fragment key={k}>
                                <tr
                                    className="bg-gray-50"
                                    // className={`bg-gray-50 grow-shrink-height ${collapse ? "shrink-height" : "grow-height"}`}
                                    >
                                    <td
                                        colSpan={allColumns.length} 
                                        className="bg-gray-50">
                                        <div className="p-1">
                                            <input
                                                type="checkbox"
                                                checked={omitRows.has(k)} 
                                                onChange={e => handleOmitCheck(e, k)} />
                                            <label className="ml-3" style={{color: "rgba(100, 100, 100, .75)"}}>Don't merge this row</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className="bg-gray-50"
                                    // className={`bg-gray-50 grow-shrink-height ${collapse ? "shrink-height" : "grow-height"}`}
                                    key={k} >{
                                    allColumns.map((cn, l) => (
                                        <td 
                                            key={l}
                                            // className={`text-sm p-1 text-gray-700 whitespace-nowrap text-left mx-2 bg-gray-50 grow-shrink-height ${collapse ? "shrink-height" : "grow-height"}`}
                                            className="text-sm p-1 text-gray-700 whitespace-nowrap text-left mx-2"
                                            style={{border: "1px dashed rgba(140, 140, 140, .25)", backgroundColor: (dbRow[cn] || cn === "No") ? "white" : "rgba(170, 170, 170, .5)"}}>
                                            {   cn === "No" ? k + 1 :
                                                cn !== "No" && dbRow[cn] ? 
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkboxData[cn].isDbRow && checkboxData[cn].dbRowIndex === k}
                                                        onChange={e => handleCheckboxes(e, "dbRow", cn, k, dbRows, fileRow)}/>
                                                    <br />
                                                </> : ""}
                                            {dbRow[cn] ? <DataWrapper str={dbRow[cn]} /> : ""}
                                        </td>))
                                }</tr>
                            </Fragment>
                        ))
                    }
                </tfoot>
            </table>
        </div>);
}

export default Conflict;