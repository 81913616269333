import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getColumnNames } from "../../firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import useGlobalStore from "../../stores/global_store";
import { changeMultipleKeys } from "../../helper_functions/parsers";

function MapModal({isOpen, onRequestClose, fileColumnNames}){

    const [DBColumnNames, setDBColumnNames] = useState([]);
    const [selectValues, setSelectValues] = useState(Array(fileColumnNames.length).fill(null));
    const [selectValuesError, setSelectValuesError] = useState(false);

    const tableJson = useGlobalStore(state => state.tableJson);
    const setTableJson = useGlobalStore(state => state.setTableJson);

    const { currentUser } = useAuth();

    const fetchColumnNames = async() => {
        let CNs = await getColumnNames(currentUser.uid);
        CNs = CNs?.data?.();
        if(CNs){
            setDBColumnNames(CNs.columnNames);
        }
    };

    useEffect(() => {
        fetchColumnNames();
    }, []);

    useEffect(() => {
        if(DBColumnNames.length > 0){
            const filledSelectValues = Array(fileColumnNames.length)
                .fill(null)
                .map((_, i) => DBColumnNames.includes(fileColumnNames[i]) ? fileColumnNames[i] : null);
            setSelectValues(filledSelectValues);
        }
    }, [DBColumnNames, fileColumnNames]);

    useEffect(() => {
        const mappings = fileColumnNames
            .map((fcn, i) => ({oldKey: fcn, newKey: selectValues[i]}))
            .filter((_, i) => selectValues[i]);
        for(let i = 0; i < mappings.length; i++){
            // first check if the mapped to value is in the old keys
            const existingOldKey = fileColumnNames.find(fcn => fcn === mappings[i].newKey);
            if(existingOldKey){
                // if it is, check that the old key is also mapped
                const oldKeyMappedTo = mappings.find(m => m.oldKey === existingOldKey);
                if(!oldKeyMappedTo){
                    setSelectValuesError(`${mappings[i].oldKey} is mapped to ${mappings[i].newKey} but ${existingOldKey} is not mapped`);
                    return;
                }
            }
        }
        setSelectValuesError(false);
        
    }, [selectValues]);

    const handleMappingChoice = (e, index) => {
        const value = e.target.value;
        setSelectValues(mi => {
            const newMI = [...mi];
            newMI[index] = value;
            return newMI;
        });
    };
    
    const getOptions = i => {
        const difference = DBColumnNames.filter(e => !selectValues.includes(e));
        return selectValues[i] ? [selectValues[i], ...difference] : difference;
    };

    const handleMap = () => {
        const mappings = fileColumnNames
            .map((fcn, i) => ({oldKey: fcn, newKey: selectValues[i]}))
            .filter((_, i) => selectValues[i]);
        const tableJsonShallowCopy = changeMultipleKeys(tableJson, mappings);
        setTableJson(tableJsonShallowCopy);
        // clean up
        setSelectValues(Array(fileColumnNames.length).fill(null));
        // close the modal
        onRequestClose();
    };

    return (<Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className="fixed right-2 top-2">
            <button onClick={onRequestClose}>X</button>
        </div>
        <div className="text-2xl">
            Map column names
        </div>
        <div>{
            fileColumnNames.map((cn, i) => (
                <div key={i} className={`flex justify-between px-2 py-1 my-1 items-center w-1/2${i % 2 === 0 ? " bg-gray-100" : ""}`}>
                    <p className="justify-self-start">{cn}</p>
                    <div>
                        <select
                            value={selectValues[i] || ""}
                            onChange={e => handleMappingChoice(e, i)}
                            className="justify-self-end"
                            style={{width: "200px"}}>
                            <option className="p-1" disabled value="">Select Mapping</option>
                            { getOptions(i).map((col, j) => <option key={j} value={col}>{col}</option>) }
                        </select>
                        <button
                            className="px-2 py-0.5 bg-gray-300"
                            onClick={() => setSelectValues(sv => {
                                const newSelectedValues = [...sv];
                                newSelectedValues[i] = null;
                                return newSelectedValues;
                            })}
                        >clear</button>
                    </div>
                </div>
            ))
        }</div>
        <div className="h-8 text-red-400">{selectValuesError ? selectValuesError : ""}</div>
        <div>
            <button className="bg-myblue text-white font-bold px-2 py-1 w-28" onClick={handleMap}>Map</button>
        </div>
    </Modal>);
}

export default MapModal;